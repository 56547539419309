<template>
  <div>
    <v-row class="ma-2">
      <v-col cols="12" class="pa-1">
        <v-text-field
          :label="country"
          v-model="myPhone"
          color="teal accent-4"
          prepend-inner-icon="mdi-phone"
          outlined
          clearable
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" class="pa-1">
        <v-select
          :items="templates"
          @change="setText"
          label="Choose a template"
          outlined
          prepend-inner-icon="mdi-book-open"
          color="teal accent-4"
          single-line
          hide-details
        ></v-select>
      </v-col>
      <v-col cols="12" class="pa-1">
        <v-textarea
          v-model="myText"
          outlined
          label="Message"
          rows="1"
          color="teal accent-4"
          clearable
          auto-grow
          hide-details
        ></v-textarea>
      </v-col>
      <v-col cols="12" class="pa-1">
        <v-btn color="teal accent-4" dark :href="url" block target="_blank" @click="saveToHistory">
          <v-icon left>mdi-whatsapp</v-icon>Open in WhatsApp
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { parse } from "libphonenumber-js";

export default {
  props: ["phone", "text"],

  computed: {
    myPhone: {
      get: function() {
        return this.phone || JSON.parse(localStorage.getItem("phone"));
      },
      set: function(v) {
        let value = v ? v.replace(/\D/g, "") : v;

        this.$router.replace({
          query: {
            ...this.$route.query,
            phone: value
          }
        });

        localStorage.setItem("phone", JSON.stringify(value));
      }
    },
    myText: {
      get: function() {
        return this.text || JSON.parse(localStorage.getItem("text"));
      },
      set: function(v) {
        if (this.myText == v) return;

        this.$router.replace({
          query: {
            ...this.$route.query,
            text: v
          }
        });

        localStorage.setItem("text", JSON.stringify(v));
      }
    },
    phoneNumber: function() {
      let p = this.myPhone ? this.myPhone.replace(/\D/g, "") : undefined;

      if (p && p.charAt(0) == "0") p = p.replace("0", "66");

      return p;
    },
    url: t =>
      `https://wa.me${t.phoneNumber ? "/" + t.phoneNumber : ""}${
        t.myText
          ? "?text=" +
            encodeURI(t.myText)
              .replace(/%5B/g, "[")
              .replace(/%5D/g, "]")
          : ""
      }`,
    country: function() {
      let data = new parse("+" + this.phoneNumber);

      if (data.country == undefined) return "Phone";

      return `${data.country} - ${this.getCountryName(data.country)}`;
    },
    templates: function() {
      return JSON.parse(localStorage.getItem("templates")) || [];
    }
  },

  methods: {
    getCountryName: function(countryCode) {
      let isoCountries = {
        AF: "Afghanistan",
        AX: "Aland Islands",
        AL: "Albania",
        DZ: "Algeria",
        AS: "American Samoa",
        AD: "Andorra",
        AO: "Angola",
        AI: "Anguilla",
        AQ: "Antarctica",
        AG: "Antigua And Barbuda",
        AR: "Argentina",
        AM: "Armenia",
        AW: "Aruba",
        AU: "Australia",
        AT: "Austria",
        AZ: "Azerbaijan",
        BS: "Bahamas",
        BH: "Bahrain",
        BD: "Bangladesh",
        BB: "Barbados",
        BY: "Belarus",
        BE: "Belgium",
        BZ: "Belize",
        BJ: "Benin",
        BM: "Bermuda",
        BT: "Bhutan",
        BO: "Bolivia",
        BA: "Bosnia And Herzegovina",
        BW: "Botswana",
        BV: "Bouvet Island",
        BR: "Brazil",
        IO: "British Indian Ocean Territory",
        BN: "Brunei Darussalam",
        BG: "Bulgaria",
        BF: "Burkina Faso",
        BI: "Burundi",
        KH: "Cambodia",
        CM: "Cameroon",
        CA: "Canada",
        CV: "Cape Verde",
        KY: "Cayman Islands",
        CF: "Central African Republic",
        TD: "Chad",
        CL: "Chile",
        CN: "China",
        CX: "Christmas Island",
        CC: "Cocos (Keeling) Islands",
        CO: "Colombia",
        KM: "Comoros",
        CG: "Congo",
        CD: "Congo, Democratic Republic",
        CK: "Cook Islands",
        CR: "Costa Rica",
        CI: "Cote D'Ivoire",
        HR: "Croatia",
        CU: "Cuba",
        CY: "Cyprus",
        CZ: "Czech Republic",
        DK: "Denmark",
        DJ: "Djibouti",
        DM: "Dominica",
        DO: "Dominican Republic",
        EC: "Ecuador",
        EG: "Egypt",
        SV: "El Salvador",
        GQ: "Equatorial Guinea",
        ER: "Eritrea",
        EE: "Estonia",
        ET: "Ethiopia",
        FK: "Falkland Islands (Malvinas)",
        FO: "Faroe Islands",
        FJ: "Fiji",
        FI: "Finland",
        FR: "France",
        GF: "French Guiana",
        PF: "French Polynesia",
        TF: "French Southern Territories",
        GA: "Gabon",
        GM: "Gambia",
        GE: "Georgia",
        DE: "Germany",
        GH: "Ghana",
        GI: "Gibraltar",
        GR: "Greece",
        GL: "Greenland",
        GD: "Grenada",
        GP: "Guadeloupe",
        GU: "Guam",
        GT: "Guatemala",
        GG: "Guernsey",
        GN: "Guinea",
        GW: "Guinea-Bissau",
        GY: "Guyana",
        HT: "Haiti",
        HM: "Heard Island & Mcdonald Islands",
        VA: "Holy See (Vatican City State)",
        HN: "Honduras",
        HK: "Hong Kong",
        HU: "Hungary",
        IS: "Iceland",
        IN: "India",
        ID: "Indonesia",
        IR: "Iran, Islamic Republic Of",
        IQ: "Iraq",
        IE: "Ireland",
        IM: "Isle Of Man",
        IL: "Israel",
        IT: "Italy",
        JM: "Jamaica",
        JP: "Japan",
        JE: "Jersey",
        JO: "Jordan",
        KZ: "Kazakhstan",
        KE: "Kenya",
        KI: "Kiribati",
        KR: "Korea",
        KW: "Kuwait",
        KG: "Kyrgyzstan",
        LA: "Lao People's Democratic Republic",
        LV: "Latvia",
        LB: "Lebanon",
        LS: "Lesotho",
        LR: "Liberia",
        LY: "Libyan Arab Jamahiriya",
        LI: "Liechtenstein",
        LT: "Lithuania",
        LU: "Luxembourg",
        MO: "Macao",
        MK: "Macedonia",
        MG: "Madagascar",
        MW: "Malawi",
        MY: "Malaysia",
        MV: "Maldives",
        ML: "Mali",
        MT: "Malta",
        MH: "Marshall Islands",
        MQ: "Martinique",
        MR: "Mauritania",
        MU: "Mauritius",
        YT: "Mayotte",
        MX: "Mexico",
        FM: "Micronesia, Federated States Of",
        MD: "Moldova",
        MC: "Monaco",
        MN: "Mongolia",
        ME: "Montenegro",
        MS: "Montserrat",
        MA: "Morocco",
        MZ: "Mozambique",
        MM: "Myanmar",
        NA: "Namibia",
        NR: "Nauru",
        NP: "Nepal",
        NL: "Netherlands",
        AN: "Netherlands Antilles",
        NC: "New Caledonia",
        NZ: "New Zealand",
        NI: "Nicaragua",
        NE: "Niger",
        NG: "Nigeria",
        NU: "Niue",
        NF: "Norfolk Island",
        MP: "Northern Mariana Islands",
        NO: "Norway",
        OM: "Oman",
        PK: "Pakistan",
        PW: "Palau",
        PS: "Palestinian Territory, Occupied",
        PA: "Panama",
        PG: "Papua New Guinea",
        PY: "Paraguay",
        PE: "Peru",
        PH: "Philippines",
        PN: "Pitcairn",
        PL: "Poland",
        PT: "Portugal",
        PR: "Puerto Rico",
        QA: "Qatar",
        RE: "Reunion",
        RO: "Romania",
        RU: "Russian Federation",
        RW: "Rwanda",
        BL: "Saint Barthelemy",
        SH: "Saint Helena",
        KN: "Saint Kitts And Nevis",
        LC: "Saint Lucia",
        MF: "Saint Martin",
        PM: "Saint Pierre And Miquelon",
        VC: "Saint Vincent And Grenadines",
        WS: "Samoa",
        SM: "San Marino",
        ST: "Sao Tome And Principe",
        SA: "Saudi Arabia",
        SN: "Senegal",
        RS: "Serbia",
        SC: "Seychelles",
        SL: "Sierra Leone",
        SG: "Singapore",
        SK: "Slovakia",
        SI: "Slovenia",
        SB: "Solomon Islands",
        SO: "Somalia",
        ZA: "South Africa",
        GS: "South Georgia And Sandwich Isl.",
        ES: "Spain",
        LK: "Sri Lanka",
        SD: "Sudan",
        SR: "Suriname",
        SJ: "Svalbard And Jan Mayen",
        SZ: "Swaziland",
        SE: "Sweden",
        CH: "Switzerland",
        SY: "Syrian Arab Republic",
        TW: "Taiwan",
        TJ: "Tajikistan",
        TZ: "Tanzania",
        TH: "Thailand",
        TL: "Timor-Leste",
        TG: "Togo",
        TK: "Tokelau",
        TO: "Tonga",
        TT: "Trinidad And Tobago",
        TN: "Tunisia",
        TR: "Turkey",
        TM: "Turkmenistan",
        TC: "Turks And Caicos Islands",
        TV: "Tuvalu",
        UG: "Uganda",
        UA: "Ukraine",
        AE: "United Arab Emirates",
        GB: "United Kingdom",
        US: "United States",
        UM: "United States Outlying Islands",
        UY: "Uruguay",
        UZ: "Uzbekistan",
        VU: "Vanuatu",
        VE: "Venezuela",
        VN: "Viet Nam",
        VG: "Virgin Islands, British",
        VI: "Virgin Islands, U.S.",
        WF: "Wallis And Futuna",
        EH: "Western Sahara",
        YE: "Yemen",
        ZM: "Zambia",
        ZW: "Zimbabwe"
      };

      if (isoCountries.hasOwnProperty(countryCode))
        return isoCountries[countryCode];
      else return countryCode;
    },
    saveToHistory: function() {
      let history = JSON.parse(localStorage.getItem("history")) || [];
      history.push({
        timestamp: Date.now(),
        phone: this.phoneNumber,
        text: this.myText
      });
      localStorage.setItem("history", JSON.stringify(history));
    },
    setText: function(v) {
      console.log(v);
      this.myText = v;
    }
  }
};
</script>
